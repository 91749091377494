.App {
  height: 100vh;
}

.loader {
  height: calc(100vh - 500px);
  display: flex;
  align-items: center;
  justify-content: center;
}
